import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import { Translation } from 'react-i18next';
import SepaIcon from '../images/payment-sepa.svg';
import PosIcon from '../images/payment-checkstand.svg';
import MasterCardIcon from '../images/payment-mastercard.svg';
import VisaIcon from '../images/payment-visa.svg';
import TerminalIcon from '../images/payment-card-terminal.svg';
import AmExIcon from '../images/payment-amex.svg';
import PayDirektIcon from '../images/payment-paydirekt.svg';
import CustomerCardIcon from '../images/payment-customercard.svg';
import TegutIcon from '../images/payment-tegut.svg';
import TwintIcon from '../images/payment-twint.svg';
import PostFinanceIcon from '../images/payment-postfinance.svg';
import ApplePayIcon from '../images/payment-apple-pay.svg';
import GooglePayIcon from '../images/payment-google-pay.svg';
import DinersClubIcon from '../images/payment-dinersclub.png';
import GiroCardIcon from '../images/payment-girocard.svg';
import DankortIcon from '../images/payment-dankort.svg';
import MaestroIcon from '../images/payment-maestro.svg';
import VpayIcon from '../images/payment-vpay.svg';
import AlipayIcon from '../images/payment-alipay.svg';
import DiscoverIcon from '../images/payment-discover.svg';
import WeChatPayIcon from '../images/payment-wechat.svg';
import JBCIcon from '../images/payment-jcb.svg';
import EftposIcon from '../images/payment-eftpos.svg';
import CupIcon from '../images/payment-cup.svg';

const styles = ({
  icon: {
    width: 'auto',
    maxHeight: '25px',
    marginTop: '2px',
  },
  textWithIcon: {
    display: 'flex',
  },
  iconLabel: {
    lineHeight: '26px',
    marginLeft: '5px',
  },
});

const chooseIcon = (method, project) => {
  switch (method) {
    case 'teleCashDeDirectDebit':
    case 'deDirectDebit':
      return SepaIcon;
    case 'encodedCodes':
    case 'qrCodePOS':
    case 'qrCodeOffline':
    case 'encodedCodesIKEA':
    case 'encodedCodesCSV':
      return PosIcon;
    case 'externalBilling':
    case 'gatekeeperExternalBilling':
    case 'customerCardPOS':
      if (project === 'tegut-eixae6') {
        return TegutIcon;
      }
      return CustomerCardIcon;
    case 'creditCardMastercard':
    case 'mastercard':
    case 'MasterCard':
    case 'master_card':
      return MasterCardIcon;
    case 'maestro':
      return MaestroIcon;
    case 'creditCardVisa':
    case 'visa':
    case 'VISA':
      return VisaIcon;
    case 'creditCardAmericanExpress':
    case 'amex':
    case 'american_express':
      return AmExIcon;
    case 'gatekeeperTerminal':
      return TerminalIcon;
    case 'paydirektOneKlick':
      return PayDirektIcon;
    case 'twint':
      return TwintIcon;
    case 'postFinanceCard':
    case 'postfinance':
    case 'post':
      return PostFinanceIcon;
    case 'applePay':
      return ApplePayIcon;
    case 'googlePay':
      return GooglePayIcon;
    case 'diners':
      return DinersClubIcon;
    case 'girocard':
      return GiroCardIcon;
    case 'dankort':
      return DankortIcon;
    case 'vpay':
      return VpayIcon;
    case 'alipay':
      return AlipayIcon;
    case 'discover':
      return DiscoverIcon;
    case 'we_chat_pay':
      return WeChatPayIcon;
    case 'jbc':
      return JBCIcon;
    case 'eftpos':
      return EftposIcon;
    case 'cup':
      return CupIcon;
    case 'visa_elektron': // For now we are using Visa for this
      return VisaIcon;
    default:
      return null;
  }
};

const chooseStyles = (paymentMethod, paymentCardType) => {
  if (paymentMethod === 'creditCardVisa' || paymentCardType === 'visa' || paymentCardType === 'VISA') {
    return {
      maxWidth: '50px',
    };
  }
  if (paymentMethod === 'creditCardMastercard' || paymentCardType === 'master_card' || paymentCardType === 'mastercard' || paymentCardType === 'MasterCard') {
    return {
      maxWidth: '90px',
    };
  }
  return {};
};

function PaymentMethodIcon({
  paymentMethod, paymentCardType, classes, withTooltip, project,
}) {
  const onMissingTranslation = () => paymentMethod;
  // NOTE sometimes there is no paymentCardType (e.g. visa via app). In this case the card type
  // is directly written to the paymentMethod
  const icon = chooseIcon(paymentCardType, project) || chooseIcon(paymentMethod, project);
  const paymentMethodTranslationID = `paymentMethod.${paymentMethod}`;
  const paymentCardTranslationID = `paymentCardType.${paymentCardType}`;

  if (icon) {
    const iconStyles = chooseStyles(paymentMethod, paymentCardType);

    if (withTooltip) {
      return (
        <Translation options={{ onMissingTranslation }}>
          {translate => (
            <Tooltip title={translate(paymentMethodTranslationID, null, { onMissingTranslation })}>
              <img
                src={icon}
                alt={translate(paymentMethodTranslationID, null, { onMissingTranslation })}
                className={classes.icon}
                style={iconStyles}
              />
            </Tooltip>
          )}
        </Translation>
      );
    }

    const iconsWithoutText = icon !== VisaIcon
      && icon !== SepaIcon
      && icon !== MasterCardIcon;
    const hidePaymentCardTypeHint = icon === MasterCardIcon
      || icon === VisaIcon
      || paymentCardType === 'unknown';
    return (
      <Translation options={{ onMissingTranslation }}>
        {translate => (
          <div className={classes.textWithIcon}>
            <img
              src={icon}
              alt={translate(paymentMethodTranslationID, null, { onMissingTranslation })}
              className={classes.icon}
              style={iconStyles}
            />
            {iconsWithoutText &&
              <span className={classes.iconLabel}>
                {translate(paymentMethodTranslationID, null, { onMissingTranslation })}
              </span>
            }
            {paymentCardType && !hidePaymentCardTypeHint &&
              <span data-testid="paymentCardTypeSpan" className={classes.iconLabel} >
                ({translate(paymentCardTranslationID, null, { onMissingTranslation })})
              </span>
            }
          </div>
        )}
      </Translation>
    );
  }
  return (<span>{paymentMethod}</span>);
}

export default withStyles(styles)(PaymentMethodIcon);
