import { removeDuplicates } from '../utils/arrayUtils';

export const FEATURE_FLAGS_LEGACY = [
  'useDigitalReceipt',
  'disableScanner',
  'skipStartPage',
  'manualCartItemQuantityInput',
  'soundOnCartAbort',
  'fullTextSearch',
  'confirmCustomerCard',
  'confirmCart',
];

// These are the feature flags for the pos and the sco-ng
export const FEATURE_FLAGS_POS = [
  'manualCartItemQuantityInput',
  'soundOnCartAbort',
  'soundOnCartSuccess',
];

const FEATURE_FLAGS = removeDuplicates([...FEATURE_FLAGS_LEGACY, ...FEATURE_FLAGS_POS]);
export default FEATURE_FLAGS;
