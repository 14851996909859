import React from 'react';
import { styled } from '@mui/styles';
// @ts-ignore
import { ReactComponent as SepaIcon } from '../images/payment-sepa.svg';
// @ts-ignore
import { ReactComponent as PosIcon } from '../images/payment-checkstand.svg';
// @ts-ignore
import { ReactComponent as MasterCardSVG } from '../images/payment-mastercard.svg';
// @ts-ignore
import { ReactComponent as VisaSVG } from '../images/payment-visa.svg';
// @ts-ignore
import { ReactComponent as TerminalIcon } from '../images/payment-card-terminal.svg';
// @ts-ignore
import { ReactComponent as AmExIcon } from '../images/payment-amex.svg';
// @ts-ignore
import { ReactComponent as PayDirektIcon } from '../images/payment-paydirekt.svg';
// @ts-ignore
import { ReactComponent as CustomerCardIcon } from '../images/payment-customercard.svg';
// @ts-ignore
import { ReactComponent as TegutIcon } from '../images/payment-tegut.svg';
// @ts-ignore
import { ReactComponent as TwintIcon } from '../images/payment-twint.svg';
// @ts-ignore
import { ReactComponent as PostFinanceIcon } from '../images/payment-postfinance.svg';
// @ts-ignore
import { ReactComponent as ApplePayIcon } from '../images/payment-apple-pay.svg';
// @ts-ignore
import { ReactComponent as GooglePayIcon } from '../images/payment-google-pay.svg';
// @ts-ignore
import DinersClubPNG from '../images/payment-dinersclub.png';
// @ts-ignore
import { ReactComponent as GiroCardIcon } from '../images/payment-girocard.svg';
// @ts-ignore
import { ReactComponent as DankortIcon } from '../images/payment-dankort.svg';
// @ts-ignore
import { ReactComponent as MaestroIcon } from '../images/payment-maestro.svg';
// @ts-ignore
import { ReactComponent as VpayIcon } from '../images/payment-vpay.svg';
// @ts-ignore
import { ReactComponent as AlipayIcon } from '../images/payment-alipay.svg';
// @ts-ignore
import { ReactComponent as DiscoverIcon } from '../images/payment-discover.svg';
// @ts-ignore
import { ReactComponent as WeChatPayIcon } from '../images/payment-wechat.svg';
// @ts-ignore
import { ReactComponent as JbcIcon } from '../images/payment-jcb.svg';
// @ts-ignore
import { ReactComponent as EftposIcon } from '../images/payment-eftpos.svg';
// @ts-ignore
import { ReactComponent as CupIcon } from '../images/payment-cup.svg';

const chooseIcon = (method: string | undefined, project: string | undefined) => {
  switch (method) {
    case 'teleCashDeDirectDebit':
    case 'deDirectDebit':
      return <SepaIcon />;
    case 'encodedCodes':
    case 'qrCodePOS':
    case 'qrCodeOffline':
    case 'encodedCodesIKEA':
    case 'encodedCodesCSV':
      return <PosIcon />;
    case 'externalBilling':
    case 'gatekeeperExternalBilling':
    case 'customerCardPOS':
      if (project === 'tegut-eixae6') {
        return <TegutIcon />;
      }
      return <CustomerCardIcon />;
    case 'creditCardMastercard':
    case 'mastercard':
    case 'MasterCard':
    case 'master_card':
      return <MasterCardSVG style={{ maxWidth: '90px' }} />;
    case 'maestro':
      return <MaestroIcon />;
    case 'creditCardVisa':
    case 'visa':
    case 'VISA':
    case 'visa_elektron': // for now we are using visa for this
      return <VisaSVG style={{ maxWidth: '50px' }} />;
    case 'creditCardAmericanExpress':
    case 'amex':
    case 'american_express':
      return <AmExIcon />;
    case 'gatekeeperTerminal':
      return <TerminalIcon />;
    case 'paydirektOneKlick':
      return <PayDirektIcon />;
    case 'twint':
      return <TwintIcon />;
    case 'postFinanceCard':
    case 'postfinance':
    case 'post':
      return <PostFinanceIcon />;
    case 'applePay':
      return <ApplePayIcon />;
    case 'googlePay':
      return <GooglePayIcon />;
    case 'diners':
      return <img src={DinersClubPNG} alt="Diners Club" />;
    case 'girocard':
      return <GiroCardIcon />;
    case 'dankort':
      return <DankortIcon />;
    case 'vpay':
      return <VpayIcon />;
    case 'alipay':
      return <AlipayIcon />;
    case 'discover':
      return <DiscoverIcon />;
    case 'we_chat_pay':
      return <WeChatPayIcon />;
    case 'jbc':
      return <JbcIcon />;
    case 'eftpos':
      return <EftposIcon />;
    case 'cup':
      return <CupIcon />;

    default:
      return null;
  }
};

const IconContainer = styled('div')(() => ({
  '& svg': {
    width: 'auto',
    maxHeight: '25px',
    marginTop: '2px',
  },
  '& img': {
    width: 'auto',
    maxHeight: '25px',
    marginTop: '2px',
  },
}));

export interface PaymentCardIconProps {
  paymentMethod: string | undefined;
  paymentCardType: string | undefined;
  project: string;
}

export default function PaymentCardIcon({
  paymentMethod,
  paymentCardType,
  project,
}: PaymentCardIconProps) {
  const PaymentIcon = chooseIcon(paymentCardType, project) || chooseIcon(paymentMethod, project);

  if (!PaymentIcon) return <span>{paymentMethod}</span>;

  return (
    <IconContainer>
      {PaymentIcon}
    </IconContainer>
  );
}
